<template>
  <span class="inline-flex items-baseline">
    <kbd
      class="flex h-5 min-w-[1.25rem] px-0.5 items-center justify-center rounded border border-gray-400 text-gray-900 bg-white font-semibold"
      ><slot />
    </kbd>
  </span>
</template>

<script setup></script>
